<template>
  <form action="" @submit.prevent="formSubmit">
    <div class="max-w-2xl mt-8 mx-auto p-4 bg-white rounded">
      <label for="" class="text-2xl">Role And Permission</label>
      <div class="text-left p-2">
        <label for="">Role</label>
        <Input
          v-model="form.name"
          type="text"
          class="w-full focus:outline-none border"
        />
      </div>
      <div class="text-left p-2">
        <label for="">Type</label>
        <Select
          v-model="form.type"
          type="text"
          class="w-full focus:outline-none border"
        >
          <option value="">Select</option>
          <option
            :value="index"
            v-for="(type, index) in roles.types"
            :key="index"
          >
            {{ type }}
          </option>
        </Select>
      </div>
      <div class="text-left p-2">
        <label for="">Permission</label>
        <div class="grid grid-cols-3 gap-2">
          <div
            class="flex items-center"
            v-for="(permission, index) in roles.permissions"
            :key="index"
          >
            <input
              id="checkbox"
              type="checkbox"
              v-model="form.permission"
              :value="index"
              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
            />
            <label
              for="checkbox"
              class="ml-2 text-sm font-medium text-gray-900"
              >{{ permission }}</label
            >
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-4">
        <div
          @click="$router.go(-1)"
          class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
        </div>
        <Btn type="submit"> Submit </Btn>
      </div>
    </div>
  </form>
</template>
<script>
import Select from "../../../components/select.vue";
import Btn from "../../../components/btn.vue";
import Input from "../../../components/input-new.vue";
import axios from "../../../store/axios.js";
export default {
  components: {
    Select,
    Btn,
    Input,
  },
  data() {
    return {
      form: {
        name: "",
        permission: [],
        type: "",
      },
    };
  },

  watch: {
    "$store.state.roles.edit_role"(data) {
      console.log("edit", data.assigns);
      console.log("edit", data.role.name);
      if (data) {
        this.form.name = data.role.name;
        this.form.type = data.role.type;
        if (data.assigns instanceof Array) {
          for (const key in data.assigns) {
            this.form.permission[key] = data.assigns[key];
          }
        } else {
          this.form.permission[0] = data.roles.permission;
        }
      }
    },
  },

  created() {
    this.$store.dispatch("roles/roleEdit", {
      id: this.roleId,
    });
  },
  computed: {
    roles() {
      return this.$store.state.roles.edit_role;
    },
    patients() {
      // return  Object(this.mentorAssigns.appointed)
    },
    roleId() {
      return this.$route.params.id;
    },
  },
  methods: {
    formSubmit() {
      this.$store
        .dispatch("roles/editDataSubmit", {
          id: this.roleId,
          data: this.form,
        })
        .then(() => {
          this.$toast.success(`Role successfully updated !!!`, {
            position: "top",
            duration: 3000,
          });

          this.$router.push({
            path: "/admin/roles",
          });
        });
    },
  },
};
</script>
